import React, { useEffect, useRef, useState } from 'react';
import { Slider } from '@material-ui/core';
import './HorizontalScrollbar.scss';
import { withStyles } from '@material-ui/core/styles';

const CustomSlider = withStyles({
  root: {
    padding: '0',
    color: '#dad9e2',
    height: 5,
  },
  thumb: {
    marginTop: 0,
    height: 5,
    width: 54,
    backgroundColor: '#1a1c24',
    borderRadius: 0,
    '&:focus, &:hover, &:active': {
      boxShadow: 'inherit',
    },
  },
  track: {
    backgroundColor: 'transparent',
    height: 5,
    borderRadius: 0,
  },
  rail: {
    height: 5,
    borderRadius: 0,
    paddingRight: 50,
  },
})(Slider);

interface Props {
  targetRef: React.MutableRefObject<HTMLElement | null | undefined>;
}

const HorizontalScrollbar = ({ targetRef }: Props): JSX.Element => {
  const [progress, setProgress] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const sliderRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (targetRef.current) {
      targetRef.current.addEventListener('scroll', () => {
        if (targetRef.current && sliderRef.current) {
          const sliderInput = sliderRef.current.getElementsByTagName('input')[0];
          const sliderSpan = sliderRef.current.getElementsByClassName('MuiSlider-thumb')[0];
          if (sliderInput && sliderSpan) {
            const currentProgress = `${
              (targetRef.current.scrollLeft / (targetRef.current.scrollWidth - targetRef.current.clientWidth)) * 100
            }`;
            sliderInput.value = currentProgress;
            sliderSpan.setAttribute('aria-valuenow', currentProgress);
            (sliderSpan as HTMLSpanElement).style.left = `${currentProgress}%`;
          }
        }
      });
    }
  }, [targetRef]);

  useEffect(() => {
    if (targetRef.current) {
      const { current } = targetRef;
      current.scrollLeft = (progress / 100) * (current.scrollWidth - current.clientWidth);
    }
  }, [progress, targetRef]);

  return (
    <div className="horizontal-scrollbar" ref={containerRef}>
      <CustomSlider
        value={progress}
        onChange={(_e, v) => setProgress(Array.isArray(v) ? v[1] : v)}
        innerRef={sliderRef}
      />
    </div>
  );
};

export default HorizontalScrollbar;
